export const convertFileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export const convertFilesToBase64Array = files => new Promise(async (resolve, reject) => {
    await Promise.all(files.map(async file => ({
        fileName: file.name,
        file: await convertFileToBase64(file)
    }))).then(convertedFiles =>  resolve(convertedFiles), reason => {
            reject(reason);
    });
});
