




































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import './scss/PopupCreateTask.scss';
import { namespace } from 'vuex-class';
import { IMemberInfo } from '@store/modules/project-team/interfaces/Interfaces';
import { IProject } from '@store/modules/project/Interfaces';
import { ProjectDocumentsActions } from '@store/modules/project-documents/Types';
import { formatDate } from '@utility/formatDate';
import { convertFilesToBase64Array } from "@utility/fileConverter";

const NSTeam = namespace('storeProjectTeam');
const NSProject = namespace('storeProject');
const NSDocuments = namespace('storeProjectDocuments');


@Component({
    name: 'PopupCreateTask',
    components: {
        BasePopup: () => import('@components/popups/BasePopup.vue'),
        BaseInput: () => import('@components/BaseInputs/BaseInput.vue'),
        BaseTextarea: () => import('@components/BaseInputs/BaseTextarea.vue'),
        BaseSelect: () => import('@components/BaseSelect/BaseSelect.vue'),
        BaseFileInput: () => import('@components/BaseFileInput/BaseFileInput.vue'),
    }
})

export default class PopupCreateTask extends Vue {
    $refs!: {
        basePopup: HTMLFormElement,
    }

    @NSProject.Getter('projectData') projectData!: IProject;
    @NSTeam.Getter('projectTeam') projectTeam!: IMemberInfo[];
    @NSDocuments.Action(ProjectDocumentsActions.A_CREATE_TASK)
    createNewTask!: (payload) => Promise<void>

    @Prop({}) taskId!: string | number;
    @Prop({ default: 'Создать задачу' }) titlePopup!: string;
    @Prop({ default: 'Название' }) headTitle!: string;
    @Prop({ default: '' }) captionFieldFile!: string;

    title: string = '';
    fields: any = {
        title: '',
        description: '',
        deadline: '',
        userIds: [],
        files: [],
    }
    btnDisabled = false;
    realtimeValidation = false;
    errors = {
        title: false,
        description: false,
        deadline: false,
        userIds: false,
    };

    get dateToday() {
        return formatDate({ date: new Date(), format: 'yyyy-mm-dd' });
    }

    get executorOptions() {
        const output: any[] = [];

        this.projectTeam.forEach((item) => {
            output.push({
                label: item.fullName,
                value: item.uuid,
            });
        })

        return output;
    }

    get projectId() {
        if (this.projectData && this.projectData.id) {
            return this.projectData.id;
        }
        return null;
    }

    get titleForExecutor() {
        if (this.fields.userIds[0]) {
            const members = this.projectTeam.filter(item => item.uuid === this.fields.userIds[0]);
            if (members[0] && members[0].fullName) {
                return members[0].fullName;
            }
        }
        return 'Выберите исполнителя';
    }

    get errorExists() {
        return Object.values(this.errors).some((item) => item);
    }

    openPopup() {
        this.$refs.basePopup.openPopup();
    }

    closePopup() {
        this.$refs.basePopup.closePopup();
        this.onClose();
    }

    onClose() {
        this.title = '';
        this.$emit('close');
    }

    setTitle(title) {
        this.title = title;
    }

    validateFields() {
        this.errors.title = !this.fields.title;
        this.errors.description = !this.fields.description;
        this.errors.deadline = !this.fields.deadline;
        this.errors.userIds = !this.fields.userIds.length;
        this.realtimeValidation = true;
    }

    checkValidation() {
        if (this.realtimeValidation) {
            this.validateFields();
        }
    }

    selectUser() {
        this.checkValidation();
    }

    onSuccess() {
        this.validateFields();
        if (!this.errorExists) {
            this.btnDisabled = true;

            const data: any = {
                appId: this.projectId,
                taskId: this.taskId,
                userIds: this.fields.userIds.map(item => item.value),
                name: this.fields.title,
                text: this.fields.description,
                deadline: this.fields.deadline ?? this.dateToday,
            };

            convertFilesToBase64Array(this.fields.files)
                .then(files => {
                    data.files = files;
                })
                .finally(() => {
                    this.createNewTask(data)
                        .then(() => {
                            this.fields = {
                                title: '',
                                description: '',
                                deadline: '',
                                userIds: [],
                                files: [],
                            }
                            this.realtimeValidation = false;
                            this.errors = {
                                title: false,
                                description: false,
                                deadline: false,
                                userIds: false,
                            };
                            this.title = '';
                            this.$emit('confirm-create-task');
                            this.closePopup();
                        })
                        .finally(() => {this.btnDisabled = false;});
                });
        }
    }

    onFileChange(e) {
        this.fields.files = e;
        this.checkValidation();
    }
}
